import React, { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import { useParams, Link as RouterLink } from 'react-router-dom';
import CriterionCard from '../components/CriterionCard';
import TierList from '../components/TierList';
import FaceOffImageList from '../components/FaceOffImageList';
import AITester from '../components/AITester';
import SEO from '../components/SEO'; // Import the new SEO component
import axios from 'axios';
import { CircularProgress, Container, Alert, Card, Typography, Tabs, Tab, Box, Button } from '@mui/material';
import CompetitorRankingTable from '../components/CompetitorRankingTable';

const LeaderboardPage = () => {
  const [criterionData, setCriterionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [leaderboardId, setLeaderboardId] = useState(null);
  const { user } = useUser();
  const { criterion_name, id } = useParams();

  useEffect(() => {
    const fetchLeaderboardDetails = async () => {
      try {
        let endpoint = '';
        if (id) {
          setLeaderboardId(id);
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboard/${id}`;
        } else if (criterion_name) {
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboardByCriterionName/${criterion_name}`;
        }
        if (!endpoint) throw new Error('Invalid URL parameters.');

        const response = await axios.get(endpoint);
        setCriterionData(response.data);
        setLeaderboardId(response.data.corelation_id);
      } catch (err) {
        setError('Error fetching leaderboard data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardDetails();
  }, [criterion_name, id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const leaderboardHeadCells = [
    { id: 'large_image_url', label: 'Image', numeric: false, isImage: true },
    { id: 'ranking', label: 'Ranking', numeric: true },
    { id: 'tier', label: 'Tier', numeric: false },
    { id: 'name', label: 'Game', numeric: false, page: 'competitor/', link_data: 'corelation_id' },
    { id: 'faceoff', label: 'Rank Now', numeric: false, criterion: 'criterion', competitor: 'corelation_id' }
  ];

  if (loading) {
    return (
      <>
        <SEO title="Loading Leaderboard..." description="Loading leaderboard data. Please wait..." />
        <Container>
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <SEO title="Error - Leaderboard" description="An error occurred while loading the leaderboard data." />
        <Container>
          <Alert severity="error">{error}</Alert>
        </Container>
      </>
    );
  }

  return (
    <div>
      <SEO
        title={criterionData ? `Leaderboard - Best ${criterionData.criterion_name}` : 'Leaderboard'}
        description={criterionData ? `Explore the leaderboard for the best ${criterionData.criterion_name}.` : 'View the leaderboard rankings for your favorite games.'}
        image={criterionData?.large_image_url}
        url={window.location.href}
      />

      <Container>
        {criterionData ? (
          <CriterionCard
            title={criterionData.name}
            name={criterionData.criterion_name}
            description={criterionData.leaderboard_description}
            avatar_url={criterionData.large_image_url}
            criterionStyles={{
              emoji: criterionData.criterion_emoji || '',
              transition_1_color: criterionData.transition_color_1 || '#4a4a4a',
              transition_2_color: criterionData.transition_color_2 || '#141414',
              font_color: criterionData.font_color || '#FFFFFF',
            }}
            tooltipText="This is a tooltip for the leaderboard"
          />
        ) : (
          <Alert severity="warning">No leaderboard data available for this ID.</Alert>
        )}

        <Box sx={{ width: '100%', mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to={`/faceoff?criterion_name=${criterionData.criterion_name}`}
            sx={{ mb: 2 }}
          >
            Rank Games
          </Button>
        </Box>

        <Box sx={{ width: '100%', mt: 4 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              '.MuiTab-root': { minWidth: 120 },
              display: { xs: 'flex', md: 'block' }
            }}
            centered={false}
          >
            <Tab label="Tier List" />
            <Tab label="Leaderboard" />
            <Tab label="Your Unseeded Games" />
            {user && user.accountType === 'admin' && <Tab label="AI Tester" />}
          </Tabs>

          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>
                <Typography variant="h5" component="div">
                  Tier List
                </Typography>
                <TierList leaderboardId={leaderboardId} />
              </Card>
            )}

            {activeTab === 1 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>
                <Typography variant="h5" component="div">
                  Leaderboard
                </Typography>
                <CompetitorRankingTable headCells={leaderboardHeadCells} leaderboardId={leaderboardId} seeded={1} defaultSortOrder="asc" />
              </Card>
            )}

            {activeTab === 2 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>
                <Typography variant="h5" component="div">
                  Your Unseeded Games
                </Typography>
                <FaceOffImageList
                  dataSource={`${process.env.REACT_APP_SERVER_URL}/GetMyUnseededCompetitorsForLeaderboard/${leaderboardId}`}
                  showSearch={false}
                  metricDescriptor="elo"
                />
              </Card>
            )}

            {activeTab === 3 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>
                <Typography variant="h5" component="div">
                  AI Tester
                </Typography>
                <AITester criterion={criterionData.criterion_name} competitorDescriptor="Video Game" criterionDescriptor="Category" />
              </Card>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default LeaderboardPage;
