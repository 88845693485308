import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CompetitorDetails from '../components/CompetitorDetails';
import { Container, CircularProgress, Typography, Alert, Box, Tabs, Tab, Card } from '@mui/material';
import axios from 'axios'; // For API requests
import CompetitorRankingTable from '../components/CompetitorRankingTable';
import SEO from '../components/SEO'; // Import the new SEO component

const CompetitorPage = ({ id, showCriterion = true }) => {
  const { corelation_id } = useParams(); // Get corelation_id from the URL
  const [competitorData, setCompetitorData] = useState(null);
  const [criterions, setCriterionsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [activeTab, setActiveTab] = useState(0); // Default tab is 1 (FaceOffImageList)
  const handleTabChange = (event, newValue) => {
      setActiveTab(newValue); // Update active tab when user clicks on a tab
    };

  // Function to fetch data from the server
  const fetchCompetitorData = async (competitorId) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/competitor/${competitorId}`;
      const response = await axios.get(url);
      setCriterionsData(response.data.criterions);
      console.log(response.data.criterions);
      setCompetitorData(response.data.competitor);
      document.title = response.data.competitor.name;
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  useEffect(() => {
    // Use the input param `id` if it exists, otherwise use the `corelation_id` from the URL params
    const competitorId = id || corelation_id;
    if (competitorId) {
      fetchCompetitorData(competitorId);
    }
  }, [id, corelation_id]); // Depend on both `id` and `corelation_id`

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container>

      {competitorData ? (
        <Container>
        <SEO
          title={`Is ${competitorData.name} the best Steam Game?`}
          description={`Compare and rank ${competitorData.name} to other similar Steam Games.`}
          image={competitorData.small_image_url}
          url={window.location.href}
        />
        <CompetitorDetails 
          competitorData={competitorData} 
          // Conditionally pass the `criterions` prop only if `showCriterion` is true
          {...(showCriterion && { criterions })}
        />
        
          <Box sx={{ width: '100%', mt: 4 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="Leaderboard Rankings" />
            <Tab label="Unseeded" />
          </Tabs>

          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Card sx={{ mt: 4, p: 2 }}>
                <CompetitorRankingTable competitorId={corelation_id} seeded={1}/>
              </Card>
            )}

            {activeTab === 1 && (
              <Card sx={{ mt: 4, p: 2 }}>
                <CompetitorRankingTable competitorId={corelation_id} seeded={0}/>
              </Card>
            )}

          </Box>
            </Box> 
            </Container> 
      ) : (
        <Typography>No competitor data found.</Typography>
      )}
    </Container>
  );
};

export default CompetitorPage;
