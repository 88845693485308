import React, { useState } from 'react';
import { Grid, Card, Typography, Button, Tooltip, IconButton } from '@mui/material';
import CriterionCard from './CriterionCard';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import Login from '../pages/Login';
import axios from 'axios';
import CompetitorDetails from './CompetitorDetails';
import { Link } from 'react-router-dom';
import SocialShare from './SocialShare';
import ShareMatchup from './ShareMatchup';

const FaceOff = ({ 
    primaryCompetitor,
    challengerCompetitor,
    criterion, 
    isAuthenticated, 
    nextPrimaryCompetitorId,
    faceOffInstanceId,
    leaderboardId,
    mode = "default"
}) => {
    const [bestCompetitor, setBestCompetitor] = useState(null);
    const [autoSubmit, setAutoSubmit] = useState(() => {
        const savedAutoSubmit = localStorage.getItem('autoSubmit');
        return savedAutoSubmit === null ? true : savedAutoSubmit === 'true';
    });

    const disableVoting = !primaryCompetitor.has_competitor || !challengerCompetitor.has_competitor;

    const handleSelectBest = (competitorId) => {
        setBestCompetitor(prev => (prev === competitorId ? null : competitorId));
        if (autoSubmit && isAuthenticated && bestCompetitor == null) {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        const data = {
            faceOffInstanceId,
            criterionId: criterion.name,
            outcome: bestCompetitor ? 2 : 1,
            winningCompetitorId: bestCompetitor,
            losingCompetitorId: bestCompetitor === primaryCompetitor.corelation_id ? challengerCompetitor.corelation_id : primaryCompetitor.corelation_id,
            ...(nextPrimaryCompetitorId !== null && { nextPrimaryCompetitorId }),
            leaderboardId,
            mode,
            primaryCompetitorId: primaryCompetitor.corelation_id,
        };
        
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/submitfaceoff`, data, { withCredentials: true });

        if (response.status === 200) {
            const { redirectUrl } = response.data;
            window.location.href = redirectUrl;
        } else {
            alert("Something went wrong");
        }
    };

    const middleButtonText = bestCompetitor ? "Submit Winner" : "Skip";

    // Toggle auto-submit state and save it to localStorage
    const handleAutoSubmitChange = () => {
        const newAutoSubmit = !autoSubmit;
        setAutoSubmit(newAutoSubmit);
        localStorage.setItem('autoSubmit', newAutoSubmit);
    };

    return (
        <Card sx={{ p: 1 }}>
            <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: { xs: 'center' } }}>
                Which is the better <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/leaderboard?criterion_name=${criterion.name}`}>'{criterion.name}'</Link> Steam Game?
            </Typography>

            <Grid container spacing={0}>
                <Grid item xs={12} sm={5} sx={{ mt: 2, mb: 2 }}>
                    <CompetitorDetails competitorData={primaryCompetitor} />
                </Grid>

                {criterion && (
                    <Grid 
                        item 
                        xs={12} 
                        sm={2} 
                        sx={{ 
                            mt: 2, 
                            mb: 2, 
                            textAlign: 'center',
                            display: { xs: 'none', sm: 'block' }
                        }}
                    >
                        <CriterionCard
                            name={criterion.name}
                            description={criterion.leaderboard_description}
                            avatar_url={criterion.image || ""}
                            showDescription={false}
                            criterionStyles={
                                criterion.transition_1_color && criterion.transition_2_color
                                    ? {
                                        transition_1_color: criterion.transition_1_color,
                                        transition_2_color: criterion.transition_2_color,
                                        emoji: criterion.emoji,
                                        font_color: criterion.font_color,
                                    }
                                    : undefined
                            }
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={5} sx={{ mt: 2, mb: 2 }}>
                    <CompetitorDetails competitorData={challengerCompetitor} />
                </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mt: 0, mr: 0, alignItems: 'center' }}>
                <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Tooltip title={!disableVoting ? '' : 'You are not eligible to vote on this competitor.'}>
                        <span>
                            <Button
                                variant={bestCompetitor === primaryCompetitor.corelation_id ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleSelectBest(primaryCompetitor.corelation_id)}
                                startIcon={bestCompetitor === primaryCompetitor.corelation_id ? <CheckCircle /> : <RadioButtonUnchecked />}
                                disabled={disableVoting || !isAuthenticated}
                            >
                                {primaryCompetitor.name}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>

                {/* Middle button with Auto Submit icon button */}
                <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'center', mb: 2, flexDirection: 'column', alignItems: 'center' }}>
                    {isAuthenticated ? (
                        <>
                            <Button variant="contained" color="secondary" onClick={handleSubmit}>
                                {middleButtonText}
                            </Button>
                            <Button
                                sx={{margin:1}}
                                variant={autoSubmit ? 'contained' : 'outlined'}
                                color="secondary"
                                onClick={handleAutoSubmitChange}
                                startIcon={autoSubmit ? <CheckCircle /> : <RadioButtonUnchecked />}
                                disabled={disableVoting || !isAuthenticated}
                            >
                                Auto-submit on selection
                            </Button>
                        </>
                    ) : (
                        <Login msg={"This matchup is just a preview. You must sign in to rank your games."} />
                    )}
                    <SocialShare
                        primaryCompetitorId={`${primaryCompetitor.corelation_id}`}
                        primaryCompetitorName={`${primaryCompetitor.name}`}
                        primaryCompetitorImage={`${primaryCompetitor.large_image_url}`}
                        challengerCompetitorId={`${challengerCompetitor.corelation_id}`}
                        challengerCompetitorName={`${challengerCompetitor.name}`}
                        criterion={`${criterion.name}`}
                    />
                    
                </Grid>

                <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Tooltip title={!disableVoting ? '' : 'You are not eligible to vote on this competitor'}>
                        <span>
                            <Button
                                variant={bestCompetitor === challengerCompetitor.corelation_id ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleSelectBest(challengerCompetitor.corelation_id)}
                                startIcon={bestCompetitor === challengerCompetitor.corelation_id ? <CheckCircle /> : <RadioButtonUnchecked />}
                                disabled={disableVoting || !isAuthenticated}
                            >
                                {challengerCompetitor.name}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Card>
    );
};

export default FaceOff;
