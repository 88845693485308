import React, { useEffect, useState } from 'react';
import { Container, CircularProgress, Alert, Typography, Link, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FaceOff from '../components/FaceOff';
import ChallengerMatchesTable from '../components/ChallengerMatchesTable';
import axios from 'axios';
import { useUser } from '../UserContext';

const FaceOffPage = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const primaryOpponentId = query.get('primary_opponent_id');
  const criterionName = query.get('criterion_name');
  const mode = query.get('mode');
  const fid = query.get('fid');
  const shareid = query.get('shareid');
  const { user } = useUser();
  const [primaryCompetitor, setPrimaryCompetitor] = useState(null);
  const [challengerCompetitor, setChallengerCompetitor] = useState(null);
  const [nextCompetitorId, setNextCompetitorId] = useState(null);
  const [faceOffInstanceId, setFaceOffInstanceId] = useState(null);
  const [leaderboardId, setLeaderboardId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [criterionData, setCriterionsData] = useState(null);
  const [competitorSelections, setCompetitorSelections] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);

  const fetchFaceOffCompetitor = async (criterionName, primaryOpponentId) => {
    try {
      console.log("mode",mode)
      let params = [];
      if (criterionName) params.push(`criterion_name=${criterionName}`);
      if (primaryOpponentId) params.push(`primary_opponent_id=${primaryOpponentId}`);
      if (mode) params.push(`mode=${mode}`);
      if (fid) params.push(`fid=${fid}`);
      if (shareid) params.push(`shareid=${shareid}`);

      const url = `${process.env.REACT_APP_SERVER_URL}/faceoff?${params.join("&")}`;
      console.info(`Retrieving matches from server: ${url}`);
      const response = await axios.get(url, { withCredentials: true });

      if (response.data.success) {
        const faceOffDetails = response.data.data.fullFaceOffDetails;
        setChallengerCompetitor(faceOffDetails.primaryCompetitor);
        setPrimaryCompetitor(faceOffDetails.challengerCompetitor);
        setIsAuthenticated(response.data.isAuthenticated);
        if (faceOffDetails.nextSelectedOpponent) {
          setNextCompetitorId(faceOffDetails.nextSelectedOpponent.competitor);
        }
        setFaceOffInstanceId(faceOffDetails.faceOffDetails.fi_id);
        setLeaderboardId(faceOffDetails.faceOffDetails.leaderboard);

        document.title = `Best ${faceOffDetails.faceOffDetails.criterion || "Game"} - ${faceOffDetails.challengerCompetitor.name} vs ${faceOffDetails.primaryCompetitor.name}`;

        const criterion = {
          name: faceOffDetails.faceOffDetails.criterion,
          leaderboard_description: faceOffDetails.faceOffDetails.description,
          image: faceOffDetails.faceOffDetails.avatar_url,
          transition_1_color: faceOffDetails.faceOffDetails.transition_color_1,
          transition_2_color: faceOffDetails.faceOffDetails.transition_color_2,
          emoji: faceOffDetails.faceOffDetails.emoji,
          font_color: faceOffDetails.faceOffDetails.font_color,
        };
        setCompetitorSelections(faceOffDetails.selections);
        setCriterionsData(criterion);
      } else if (response.data.info && response.data.info != "") {
        setInfo(response.data.info);
      } else {
        setError(response.data.error);
      }
      setLoading(false);
    } catch (err) {
      setError('Could not find a valid matchup: ' + err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaceOffCompetitor(criterionName, primaryOpponentId);
  }, [primaryOpponentId, criterionName, mode]);

  if (loading) {
    return (
      <Container>
        <Typography variant="h4" component="div">
          Finding games to rank...
        </Typography>
        <CircularProgress />
      </Container>
    );
  }

  if (info || error) {
    const severity = error ? 'error' : 'info'; // Set severity based on which is true
    const message = error || info; // Display the relevant message
  
    return (
      <Container>
        <Card sx={{ p: 2 }}>
          <Alert severity={severity}>{message}</Alert>
          <Typography variant="h5" component="div" gutterBottom sx={{ textAlign: 'left', p: 1 }}>
            You may want to <Link href="/faceoff?mode=personalised">start a new matchup</Link> or explore a <Link href="/faceoff?mode=random">random matchup</Link>.
          </Typography>
        </Card>
      </Container>
    );
  }
  



  return (
    <Container>
      <FaceOff
        primaryCompetitor={primaryCompetitor}
        challengerCompetitor={challengerCompetitor}
        criterion={criterionData}
        isAuthenticated={isAuthenticated}
        nextPrimaryCompetitorId={nextCompetitorId}
        faceOffInstanceId={faceOffInstanceId}
        leaderboardId={leaderboardId}
        mode={mode}
      />
       

      {user && user.accountType === 'admin' && competitorSelections && (
        <Card sx={{ mt: 3, p: 2 }}>
          <Typography variant="h4" component="div">
            Challenger matches
          </Typography>
          <Typography variant="body2" component="div">
            Below shows the results of the matching algorithm for potential challenger competitors against "{primaryCompetitor.name}". All four scores are weighted to form a total score, where the lowest scoring non-excluded competitor gets picked. Games will be excluded if previously matched or if they exceed a given threshold value.
          </Typography>
          <ChallengerMatchesTable rows={competitorSelections} /> 
        </Card>
      )}
    </Container>
  );
};

export default FaceOffPage;
