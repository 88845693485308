import React, { useState } from 'react';
import axios from 'axios';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  RedditShareButton,
} from 'react-share';
import { Chip, Popper, Paper, ClickAwayListener, IconButton, Tooltip, CircularProgress } from '@mui/material';
import { Share as ShareIcon, ContentCopy as CopyIcon } from '@mui/icons-material';

/**
 * SocialShare Component
 * 
 * Provides social media sharing buttons and a "Copy to Clipboard" option for sharing a URL.
 * Automatically fetches the sharing data when clicked.
 * 
 * @param {Object} params - The parameters for fetching share data.
 * @param {string} params.primaryCompetitorId - The ID of the primary competitor.
 * @param {string} params.primaryCompetitorName - The name of the primary competitor.
 * @param {string} params.primaryCompetitorImage - The image URL of the primary competitor.
 * @param {string} params.challengerCompetitorId - The ID of the challenger competitor.
 * @param {string} params.challengerCompetitorName - The name of the challenger competitor.
 * @param {string} params.criterion - The criterion for the matchup.
 * @param {string} params.displayName - The label to display on the share button (default: 'Share this matchup!').
 * @returns {JSX.Element} The rendered social sharing component.
 */
const SocialShare = ({
  primaryCompetitorId,
  primaryCompetitorName,
  primaryCompetitorImage,
  challengerCompetitorId,
  challengerCompetitorName,
  criterion,
  displayName = 'Share this matchup!', // Default value for displayName
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shareData, setShareData] = useState(null);

  /**
   * Handles fetching the share data from the server.
   */
  const fetchShareData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/share_faceoff_match`, {
        primary_competitor_id: primaryCompetitorId,
        primary_competitor_name: primaryCompetitorName,
        primary_competitor_image: primaryCompetitorImage,
        challenger_competitor_id: challengerCompetitorId,
        challenger_competitor_name: challengerCompetitorName,
        criterion,
      });

      setShareData(response.data);
    } catch (err) {
      console.error('Error fetching share data:', err);
      setError('Failed to load share data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles opening the Popper for sharing options.
   */
  const handleChipClick = (event) => {
    if (!shareData) {
      fetchShareData();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /**
   * Handles closing the Popper for sharing options.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Copies the provided URL to the clipboard and alerts the user upon success.
   */
  const handleCopyToClipboard = () => {
    if (shareData?.url) {
      navigator.clipboard.writeText(shareData.url).then(() => {
        alert(`Copied URL ${shareData.url} to clipboard!`);
      });
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {/* Share Chip */}
      <Chip
        icon={<ShareIcon />}
        label={
          loading ? (
            <CircularProgress size={16} />
          ) : error ? (
            'Retry'
          ) : (
            displayName // Display the dynamic label
          )
        }
        onClick={handleChipClick}
        sx={{
          backgroundColor: 'lightgray',
          '&:hover': { backgroundColor: 'darkgray' },
          cursor: 'pointer',
        }}
      />

      {/* Popper for Share Options */}
      {shareData && (
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
          <ClickAwayListener onClickAway={handleClose}>
            <Paper sx={{ p: 1, display: 'flex', gap: 1 }}>
              {/* Copy to Clipboard */}
              <Tooltip title="Copy URL to clipboard" arrow>
                <IconButton
                  onClick={handleCopyToClipboard}
                  sx={{
                    backgroundColor: 'lightgray',
                    '&:hover': { backgroundColor: 'darkgray' },
                    borderRadius: '50%',
                    width: 32,
                    height: 32,
                  }}
                >
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              {/* Social Media Buttons */}
              <Tooltip title="Share on Facebook" arrow disableInteractive>
                <div>
                  <FacebookShareButton url={shareData.url} quote={shareData.title}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </div>
              </Tooltip>
              <Tooltip title="Share on Twitter" arrow disableInteractive>
                <div>
                  <TwitterShareButton url={shareData.url} title={shareData.title}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </div>
              </Tooltip>
              <Tooltip title="Share on Reddit" arrow disableInteractive>
                <div>
                  <RedditShareButton url={shareData.url} title={shareData.title}>
                    <RedditIcon size={32} round />
                  </RedditShareButton>
                </div>
              </Tooltip>
              <Tooltip title="Share on WhatsApp" arrow disableInteractive>
                <div>
                  <WhatsappShareButton url={shareData.url} title={shareData.title}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </Tooltip>
              <Tooltip title="Share via Email" arrow disableInteractive>
                <div>
                  <EmailShareButton url={shareData.url} subject={shareData.title}>
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>
              </Tooltip>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}

      {error && (
        <div style={{ color: 'red', marginTop: '8px' }}>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default SocialShare;
