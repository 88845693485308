import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import EnhancedTable from './FaceOffTable';
import FaceOffTableV2 from './FaceOffTablev2';
import axios from 'axios';

const headCells = [
  {
    id: 'large_image_url',
    label: 'Image',
    type: 'image', // New type for images
    properties: {} // No additional properties needed
  },
  {
    id: 'name',
    label: 'Game',
    type: 'link', // New type for links
    hideMobile:true,
    properties: { page_name: 'competitor/', link_data: 'corelation_id' } // Properties for link generation
  },
  {
    id: 'leaderboard_name',
    label: 'Leaderboard',
    type: 'link', // New type for links
    properties: { defaultEmoji: '🏆', page_name: 'Leaderboard/id/', link_data: 'leaderboard_id' } // Properties for link generation
  },
  {
    id: 'relevancy_metric',
    label: 'Elo Rating',
    type: 'text', // Default type; can be omitted
    hideMobile:true,
    properties: {} // No additional properties
  },
  {
    id: 'tier',
    label: 'Tier',
    type: 'tier', // Default type; can be omitted
    properties: {} // No additional properties
  },
  {
    id: 'faceoff',
    label: 'Rank Now',
    type: 'faceoff', // New type for faceoff links
    properties: { criterion: 'criterion', competitor: 'corelation_id' } // Faceoff-specific properties
  },
  {
    id: 'share',
    label: 'Share',
    type: 'share', // New type for sharing
    properties: {
      primaryCompetitorId: 'corelation_id',
      primaryCompetitorName: 'name',
      primaryCompetitorImage: 'large_image_url',
      criterion: 'criterion'
    }
  }
];

const TopCompetitorsTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/GetBestOfCompetitors`);
        console.log(response.data)
        setRows(response.data); // Assuming the API returns the array of rows directly
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }


  return (
    <FaceOffTableV2
      rows={rows}
      headCells={headCells}
      defaultSortField="relevancy_metric"
      defaultSortOrder="desc"
      enableSearch={true}
    />

  );
};

export default TopCompetitorsTable;
